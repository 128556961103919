
/**
 * @name: 统计分析-市场月度结余库存查询
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 统计分析-市场月度结余库存查询
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { statisticsMarketStockQueryApi, statisticsExportMarketStockExcelApi } from "@/apis/statistics/market"
import type { IMarket, IMarketParam } from "@/apis/statistics/market/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { exportFile } from "@/utils/common";

@Component({})
export default class statisticsMarket extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IMarket[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IMarketParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IMarket> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "月份",
        prop: "startTime",
        search: true,
        hide: true,
        searchSlot: true
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        align: "center"
      },
      {
        label: "采购单位",
        prop: "purchaseUnit",
        align: "center",
        width: 120
      },
      {
        label: "成本价1(按采购数计算)",
        prop: "purchaseCostPrice",
        align: "center",
        width: 180
      },
      {
        label: "成本价2(按实际入库数计算)",
        prop: "checkCostPrice",
        align: "center",
        width: 180
      },
      {
        label: "上月结余库存",
        prop: "lastMonthStock",
        align: "center",
        width: 150
      },
      {
        label: "本月实际入库数",
        prop: "checkinQty",
        align: "center",
        width: 150
      },
      {
        label: "本月实际出库数",
        prop: "checkoutQty",
        align: "center",
        width: 150
      },
      {
        label: "本月结余库存",
        prop: "thisMonthStock",
        align: "center",
        width: 150
      },
    ]
  }

  reset () {
    const date = new Date()
    this.queryParam.startTime = `${date.getFullYear()}-${(date.getMonth()).toString().padStart(2, '0')}`
    this.getList()
  }

  getList () {
    this.tableLoading = true
    statisticsMarketStockQueryApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  exportExcel () {
    statisticsExportMarketStockExcelApi(this.queryParam).then(e => {
      exportFile(e, `市场${this.queryParam.startTime}月度结余库存.xlsx`)
    })
  }

  created () {
    this.reset()
  }
}
