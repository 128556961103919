/**
 * @name: 统计分析-市场月度结余库存查询接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 统计分析-市场月度结余库存查询接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IMarket, IMarketParam } from "./types";

/**
 * 市场月度结余库存分页查询
 * @param params
 * @returns
 */
export const statisticsMarketStockQueryApi = (params: IMarketParam) => get<IPageRes<IMarket[]>>("/admin/statistics/marketStockQuery", params)
/**
 * 市场月度结余库存导出
 * @param params
 * @returns
 */
export const statisticsExportMarketStockExcelApi = (params: IMarketParam) => postJ("/admin/statistics/exportMarketStockExcel", params, "blob")
